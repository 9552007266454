import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiBook } from 'react-icons/fi';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  LabDsBreadcrumb,
  LabDsButton,
  LabDsSearchBar,
  LabDsTab,
} from 'v4web-components-react';
import { useInView } from 'react-intersection-observer';
import { parseCookies } from 'nookies';
import { Loading } from '../../components/LegacyV4Components';
import { useAuth } from '../../hooks/auth';

import { DashboardCard } from '../../components/AtomicDesign/molecules/DashboardCard';
import { DashboardViewer } from '../../components/AtomicDesign/molecules/DashboardViewer';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';
import { UnitManagement } from '../../components/AtomicDesign/organisms/UnitManagement';

import { Dashboard } from '../../services/dashboardsRequests';

import * as S from './styles';
import { DashboardOperationModal } from '../../components/AtomicDesign/molecules/DashboardOperationModal';
import useDebounce from '../../hooks/useDebounce';
import { dashboardsApi } from '../../services/api';
import {
  getDashboardsPermission,
  IDashboardsPermission,
} from '../../services/requests/Dashboards/getDashboardsPermission';
import { LeadBroker } from '../../components/AtomicDesign/organisms/LeadBroker';

type IDisplayDashboard = Dashboard | undefined;

enum Tabs {
  units = 'units',
  headquarter = 'headquarter',
  favorites = 'favorites',
  leadbroker = 'leadbroker',
}

export function Dashboards() {
  const [displayDashboard, setDisplayDashboard] = useState<IDisplayDashboard>();
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState<Tabs>(Tabs.headquarter);
  const [isModalOperationOpen, setIsModalOperationOpen] = useState(false);

  const { user } = useAuth();

  const onlyFavorited = tab === Tabs.favorites;

  const isHeadquarterUser =
    user.unitId === `${process.env.REACT_APP_HEADQUARTER_ID}`;
  const [userPermission, setUserPermission] = useState<IDashboardsPermission>();

  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const debouncedQuery = useDebounce<string>(search, 500);

  const { ref: dashboardsRef, inView: dashboardsInView } = useInView();
  const { ref: favoritesRef, inView: favoritesInView } = useInView();

  const {
    data: dashboardsData,
    fetchNextPage: fetchNextDashboardsPage,
    error: dashboardsError,
    isLoading: isDashboardsLoading,
  } = useInfiniteQuery(
    ['dashboards', debouncedQuery],
    async ({ pageParam = 1 }) => {
      const customersPromise = (await dashboardsApi({
        method: 'GET',
        url: '/dashboard',
        params: {
          q: debouncedQuery,
          page: pageParam,
          limit: 20,
        },
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => res.data)) as any;
      return customersPromise;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const {
    data: favoritesData,
    fetchNextPage: fetchNextFavoritesPage,
    error: favoritesError,
  } = useInfiniteQuery(
    ['favorites', debouncedQuery],
    async ({ pageParam = 1 }) => {
      const customersPromise = (await dashboardsApi({
        method: 'GET',
        url: '/favorite',
        params: {
          q: debouncedQuery,
          page: pageParam,
          limit: 20,
        },
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => res.data)) as any;
      return customersPromise;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const renderContent = (tabContent: string) => {
    if (tabContent === Tabs.units)
      return <UnitManagement unitId={user.unitId} />;
    if (tabContent === Tabs.leadbroker) {
      return <LeadBroker unitId={user.unitId} />;
    }
    return null;
  };

  useEffect(() => {
    if (dashboardsInView) {
      fetchNextDashboardsPage();
    }
  }, [dashboardsInView, fetchNextDashboardsPage]);

  useEffect(() => {
    if (favoritesInView) {
      fetchNextFavoritesPage();
    }
  }, [favoritesInView, fetchNextFavoritesPage]);

  const [dashboards, setDashboards] = useState<any[]>([]);

  useMemo(() => {
    const auxDashboards: any[] = [];

    dashboardsData?.pages?.map((page) => {
      auxDashboards.push(...page.data);

      return;
    });

    setDashboards(auxDashboards);
  }, [dashboardsData?.pages]);

  const [favorites, setFavorites] = useState<any[]>([]);

  useMemo(() => {
    const auxFavorites: any[] = [];

    favoritesData?.pages?.map((page) => {
      auxFavorites.push(...page.data);

      return;
    });

    setFavorites(auxFavorites);
  }, [favoritesData?.pages]);

  useEffect(() => {
    if (onlyFavorited) {
      setDisplayDashboard(favorites[0]);
    } else {
      setDisplayDashboard(dashboards[0]);
    }
  }, [dashboards, favorites, onlyFavorited]);

  const selectedDashboardsTab = onlyFavorited ? favorites : dashboards;

  const unhappyConditions = {
    noResults: {
      title: (query: string) => `Nenhum resultado encontrado para "${query}"`,
      description: [
        'Tente buscar novamente com termos menos específicos',
        'Verifique se a palavra foi digitada corretamente',
        'Utilize palavras mais genéricas ou menos palavras',
      ],
    },
    noFavoriteDashboards: {
      title: 'Você ainda não salvou dashboards',
      description: [],
    },
  };

  const isNoResults = dashboards?.length === 0 && search;
  const isNoFavoriteDashboards =
    onlyFavorited && !favoritesError && favorites.length === 0;
  const unhappyCondition = isNoResults || isNoFavoriteDashboards;

  useEffect(() => {
    async function getUserPerm() {
      const permission = await getDashboardsPermission(user?._id);
      setUserPermission(permission);
    }

    getUserPerm();
  }, [user?._id, user?.unitId]);

  return (
    <S.Container>
      <S.RedirectToKnowledgeBaseButton>
        <Link to="/baseconhecimento">
          <FiBook /> <p>Base de conhecimento</p>
        </Link>
      </S.RedirectToKnowledgeBaseButton>

      <LabDsBreadcrumb
        breadcrumbs={[
          {
            label: 'Central de dashboards',
            link: '',
            key: 'home',
            event: () => null,
          },
        ]}
      />

      <S.Content>
        <h1>Central de Dashboards</h1>
        <section>
          <LabDsTab
            value={tab}
            tabs={[
              // In the future we may use another embedded instead but for now it should be disabled
              // {
              //   title: 'Gestão das unidades',
              //   key: Tabs.units,
              //   event: () => setTab(Tabs.units),
              // },
              {
                title: 'Headquarter',
                key: Tabs.headquarter,
                event: () => setTab(Tabs.headquarter),
              },
              {
                title: 'Salvos',
                key: Tabs.favorites,
                event: () => setTab(Tabs.favorites),
              },
              {
                title: 'Lead Broker',
                key: Tabs.leadbroker,
                event: () => setTab(Tabs.leadbroker),
              },
            ]}
          />

          {tab !== Tabs.units && tab !== Tabs.leadbroker && (
            <LabDsSearchBar
              value={search}
              onChangeInput={({ detail }) => setSearch(detail)}
              label="Busque por  nome ou publicação"
            />
          )}
        </section>

        <>
          {renderContent(tab)}
          {tab === Tabs.units || tab === Tabs.leadbroker ? null : (
            <>
              <header>
                <article>
                  {!unhappyCondition ? (
                    <>
                      <h3>{displayDashboard?.name}</h3>
                      <p>{displayDashboard?.description}</p>
                    </>
                  ) : null}
                </article>

                {(userPermission?.admin || userPermission?.edit) &&
                !onlyFavorited ? (
                  <LabDsButton
                    label="Adicionar Dashboard"
                    onHandleButton={() => setIsModalOperationOpen(true)}
                  />
                ) : null}
              </header>
              {isDashboardsLoading && (
                <S.Loading>
                  <Loading containerStyles={{ width: '2rem' }} />
                </S.Loading>
              )}
              {!!dashboardsError ||
              (!dashboards.length && !isDashboardsLoading) ? (
                <p>Erro ao carregar dashboards</p>
              ) : null}
              {unhappyCondition ? (
                <UnHappyPath
                  assetIndex={0}
                  title={
                    isNoResults
                      ? unhappyConditions.noResults.title(search)
                      : unhappyConditions.noFavoriteDashboards.title
                  }
                  descriptionItems={
                    isNoResults
                      ? unhappyConditions.noResults.description
                      : unhappyConditions.noFavoriteDashboards.description
                  }
                />
              ) : (
                <main>
                  {displayDashboard && (
                    <DashboardViewer dashboard={displayDashboard} />
                  )}
                  <S.Cards>
                    {selectedDashboardsTab.map((dashboard) => {
                      return (
                        <DashboardCard
                          dashboard={dashboard}
                          isSelected={dashboard._id === displayDashboard?._id}
                          onClick={() => setDisplayDashboard(dashboard)}
                          userPermission={userPermission}
                          isFavorited={dashboard.isFavorite || onlyFavorited}
                        />
                      );
                    })}
                    <span ref={onlyFavorited ? favoritesRef : dashboardsRef} />
                  </S.Cards>
                </main>
              )}
            </>
          )}
        </>
      </S.Content>

      <DashboardOperationModal
        isOpen={isModalOperationOpen}
        isHeadquarterUser={isHeadquarterUser}
        operation="create"
        closeModal={() => setIsModalOperationOpen(false)}
      />
    </S.Container>
  );
}
