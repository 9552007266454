import { useState, useRef, SetStateAction } from 'react';
import { FormHandles } from '@unform/core';
import { NumberFormatValues } from 'react-number-format';
import * as Yup from 'yup';

import { FieldArray, Formik, getIn } from 'formik';
import { AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { MdOutlineLocationOn } from 'react-icons/md';
import { MdPersonOutline } from 'react-icons/md';
import { Modal } from '../../../LegacyV4Components';
import { FormikCepInput } from '../FormikCepInput';
import { TextField } from '../TextField';
// import { Input } from '../../../Default/Input';
import { FormikCpfInput } from '../FormikCpfInput';
import { FormikPhoneInput } from '../FormikPhoneInput';
import { NumberField } from '../NumberField';
import { useToast } from '../../../../hooks/toast';

// import { getUnit } from '../../../../services/requests/Unit/getUnit';
import { updateUnit } from '../../../../services/requests/Unit/updateUnit';
import { UnitInterface } from '../../../../types/units';
import { Unit } from '../../../../hooks/types';

import { getValidationErrors } from '../../../../utils/getValidationErrors';
import { Button } from '../../../Default/Button';
import { ModalLoading } from '../../../Default/Loadings/ModalLoading';

import * as S from './styles';

interface EditUnitProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  data: UnitInterface;
  getUnit: React.Dispatch<SetStateAction<Unit | undefined>>;
}

export function EditUnit({ isOpen, setIsOpen, data, getUnit }: EditUnitProps) {
  const [loading, setLoading] = useState(false);
  const createUnitForm = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [errorUnique, setErrorUnique] = useState({
    name: '',
    socialName: '',
    cnpj: '',
    stakeholders: [
      {
        cpf: '',
        email: '',
        phone: '',
        rg: '',
      },
    ],
  });

  const [disableSend, setDisableSend] = useState(false);

  const sendFormRef = useRef<HTMLButtonElement>(null);

  const schema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório!'),
    socialName: Yup.string().required('O campo é obrigatório!'),
    cnpj: Yup.number().required('O campo é obrigatório!'),
    address: Yup.object().shape({
      state: Yup.string().required('O campo é obrigatório!'),
      city: Yup.string().required('O campo é obrigatório'),
      district: Yup.string().required('O campo é obrigatório!'),
      street: Yup.string().required('O campo é obrigatório!'),
      number: Yup.string().required('O campo é obrigatório!'),
      complement: Yup.string().required('O campo é obrigatório!'),
      zipCode: Yup.string().required('O campo é obrigatório!'),
    }),
    stakeholders: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('O campo é obrigatório'),
        email: Yup.string()
          .required('O campo é obrigatório')
          .max(255, 'No máximo 255 dígitos.')
          .min(3, 'No mínimo 3 dígitos.')
          .matches(
            RegExp('^[A-Za-z0-9._%+-]+@v4company.com$'),
            'O email deve ser do domínio @v4company',
          ),
        phone: Yup.string()
          .required('O campo é obrigatório')
          .max(11, 'No máximo 11 dígitos.')
          .min(10, 'Digite o número completo.'),
        cpf: Yup.string().required('O campo é obrigatório'),
        rg: Yup.string().required('O campo é obrigatório'),
      }),
    ),
  });
  return (
    <Modal
      title="Editar informações"
      show={isOpen}
      closeButton
      size="lg"
      footer
      disabled={disableSend}
      handlerSubmit={() => sendFormRef.current?.click()}
      labelSubmit="Salvar alterações"
      onBackground={() => setIsOpen(false)}
    >
      <Formik
        initialValues={{
          name: data.name,
          socialName: data.socialName,
          cnpj: data.cnpj?.replace(/[^a-zA-Z0-9]/g, ''),
          address: {
            state: data.address.state,
            city: data.address.city,
            district: data.address.district,
            street: data.address.street,
            number: data.address.number,
            complement: data.address.complement,
            zipCode: data.address.zipCode?.replace(/[^a-zA-Z0-9]/g, ''),
          },
          stakeholders: data.stakeholders,
        }}
        validationSchema={schema}
        onSubmit={async () => {
          setDisableSend(true);
          // handleSubmit;
          setDisableSend(false);
        }}
      >
        {({
          errors,
          setErrors,
          values,
          handleBlur,
          setFieldValue,
          touched,
          isValid,
          handleChange,
        }) => (
          <S.Container
            ref={createUnitForm}
            onSubmit={async () => {
              setDisableSend(true);

              try {
                if (!isValid) {
                  addToast({
                    type: 'error',
                    title: 'Preencha todos os campos corretamente!',
                    description: ``,
                  });
                  return;
                }
                // setLoading(true);

                if (!data._id) return;
                const unit = await updateUnit(data._id, {
                  ...values,
                  legacyUnitId: data.legacyUnitId,
                });
                if (typeof unit === 'string') {
                  if (unit === 'error') {
                    addToast({
                      type: 'error',
                      title: 'Unidade não foi criada!',
                      description: 'Tente novamente mais tarde',
                    });
                    return;
                  }
                  const error = unit as string;
                  const errorArray = JSON.parse(error);
                  const stakeholders: any = {
                    cpf: '',
                    rg: '',
                    phone: '',
                    email: '',
                  };
                  let errorsUnique = {
                    name: '',
                    socialName: '',
                    cnpj: '',
                    stakeholders: [
                      {
                        cpf: '',
                        email: '',
                        phone: '',
                        rg: '',
                      },
                    ],
                  };
                  errorArray.forEach((e: any) => {
                    if (e.name.includes('name')) {
                      errorsUnique = { ...errorsUnique, name: e.error };
                    }
                    if (e.name.includes('socialName')) {
                      errorsUnique = { ...errorsUnique, socialName: e.error };
                    }
                    if (e.name.includes('cnpj')) {
                      errorsUnique = { ...errorsUnique, cnpj: e.error };
                    }
                    if (e.name.includes('stakeholders')) {
                      const index = e.name.split(/[..]/)[1];
                      const property = e.name.split(/[..]/)[2];

                      stakeholders[index] = { [property]: e.error };
                      errorsUnique = {
                        ...errorsUnique,
                        stakeholders,
                      };
                    }
                  });
                  addToast({
                    type: 'error',
                    title: 'Não foi possível editar as informações',
                    description: 'Confira os dados informados',
                  });
                  setErrors(errorsUnique);
                  return;
                }

                if (unit) {
                  getUnit(unit as any);
                  addToast({
                    type: 'success',
                    title: 'Informações editadas com sucesso!',
                    description: ``,
                  });
                  window.location.reload();
                } else {
                  addToast({
                    type: 'error',
                    title: 'Não foi possível editar as informações',
                    description: `Unidade não foi editada!`,
                  });
                }

                setLoading(false);
                setIsOpen(false);
              } catch (err) {
                if (err instanceof Yup.ValidationError) {
                  const errorsYup = getValidationErrors(err);

                  createUnitForm.current?.setErrors(errorsYup);
                }
              } finally {
                setDisableSend(false);
              }
            }}
          >
            {loading ? (
              <S.Loading>
                <ModalLoading rows={2} />
              </S.Loading>
            ) : (
              <>
                <span>Campos obrigatórios*</span>
                <h5>
                  <AiOutlineInfoCircle className="icon" size={24} />
                  Informações da empresa
                </h5>
                <TextField
                  name="name"
                  label="Nome completo*"
                  value={values.name}
                  width="50%"
                  placeholder="Digite o nome do responsável"
                  helper={touched?.name && errors?.name ? errors?.name : ''}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onBlur={handleBlur}
                  onChange={() =>
                    setErrorUnique({
                      ...errorUnique,
                      name: 'oi',
                    })
                  }
                />
                {/* {errorUnique.name && !errors.name ? (
                  <S.ErrorTag>{errorUnique.name}</S.ErrorTag>
                ) : null} */}
                <TextField
                  name="socialName"
                  label="Razão social*"
                  value={values.socialName}
                  width="50%"
                  placeholder="Digite a razão social da unidade"
                  helper={
                    touched?.socialName && errors?.socialName
                      ? errors?.socialName
                      : ''
                  }
                  validateOnChange={false}
                  validateOnBlur={false}
                  onBlur={handleBlur}
                />
                <NumberField
                  format="##.###.###/####-##"
                  name="cnpj"
                  label="CNPJ*"
                  width="50%"
                  value={values.cnpj || undefined}
                  placeholder="Digite o CNPJ da unidade"
                  helper={touched?.cnpj && errors?.cnpj ? errors?.cnpj : ''}
                  validateOnChange={false}
                  onBlur={handleBlur}
                  allowLeadingZeros
                  isNumericString={false}
                  onValueChange={(val: NumberFormatValues) => {
                    setFieldValue(`cnpj`, val.value);
                  }}
                />
                <hr />
                <h5>
                  <MdOutlineLocationOn className="icon" size={24} />
                  Localização da unidade
                </h5>
                <S.InputRow className="first">
                  <FormikCepInput
                    format="#####-###"
                    name="address.zipCode"
                    label="CEP*"
                    value={values.address.zipCode}
                    placeholder="Digite o CEP da unidade"
                    validateOnChange={false}
                    onBlur={handleBlur}
                    onValueChange={(val: NumberFormatValues) => {
                      setFieldValue(`address.zipCode`, val.value);
                    }}
                    helper={
                      touched?.address?.zipCode && errors?.address?.zipCode
                        ? errors?.address?.zipCode
                        : ''
                    }
                  />
                  <TextField
                    name="address.state"
                    label="Estado*"
                    value={values.address.state}
                    placeholder="Digite o estado da unidade"
                    helper={
                      touched?.address?.state && errors?.address?.state
                        ? errors?.address.state
                        : ''
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="address.city"
                    label="Cidade*"
                    value={values.address.city}
                    placeholder="Digite a cidade da unidade"
                    helper={
                      touched?.address?.city && errors?.address?.city
                        ? errors?.address.city
                        : ''
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="address.street"
                    label="Endereço*"
                    value={values.address.street}
                    placeholder="Digite o endereço da unidade"
                    helper={
                      touched?.address?.street && errors?.address?.street
                        ? errors?.address.street
                        : ''
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                    onBlur={handleBlur}
                  />
                </S.InputRow>
                <S.InputRow>
                  <S.InputCol>
                    <TextField
                      name="address.district"
                      label="Bairro*"
                      value={values.address.district}
                      placeholder="Digite o bairro da unidade"
                      helper={
                        touched?.address?.district && errors?.address?.district
                          ? errors?.address.district
                          : ''
                      }
                      validateOnChange={false}
                      validateOnBlur={false}
                      onBlur={handleBlur}
                    />
                  </S.InputCol>
                  <S.InputCol>
                    <NumberField
                      format="######"
                      name="address.number"
                      label="Número*"
                      value={values.address.number || undefined}
                      placeholder="Digite o número da unidade"
                      helper={
                        touched?.address?.number && errors?.address?.number
                          ? errors?.address.number
                          : ''
                      }
                      validateOnChange={false}
                      onBlur={handleBlur}
                      allowLeadingZeros
                      isNumericString={false}
                      onValueChange={(val: NumberFormatValues) => {
                        setFieldValue(`address.number`, val.value);
                      }}
                    />
                  </S.InputCol>
                  <S.InputCol className="complement">
                    <TextField
                      name="address.complement"
                      label="Complemento*"
                      value={values.address.complement}
                      placeholder="Ex: Apartamento, próximo à, em frente à..."
                      helper={
                        touched?.address?.complement &&
                        errors?.address?.complement
                          ? errors?.address.complement
                          : ''
                      }
                      validateOnChange={false}
                      validateOnBlur={false}
                      onBlur={handleBlur}
                    />
                  </S.InputCol>
                </S.InputRow>

                <FieldArray name="stakeholders">
                  {({ push }) => {
                    return (
                      <div>
                        <S.HeaderRow>
                          <h5>
                            <MdPersonOutline className="icon" size={24} />
                            Stakeholder Principal
                          </h5>
                          <S.Buttons>
                            <Button
                              onClick={() =>
                                push({
                                  name: '',
                                  email: '',
                                  phone: '',
                                  cpf: '',
                                  rg: '',
                                })
                              }
                              small
                            >
                              Adicionar outro stakeholder{' '}
                              <AiOutlinePlus size={20} />
                            </Button>
                          </S.Buttons>
                        </S.HeaderRow>

                        {values.stakeholders.map((stakeholder, index) => {
                          return (
                            <>
                              {index > 0 ? <hr /> : null}

                              <S.InputRow className="mb">
                                <S.InputColStakeholder className="fe mb">
                                  <TextField
                                    name={`stakeholders.[${index}].name`}
                                    label="Nome*"
                                    value={stakeholder.name}
                                    placeholder="Digite o nome do stakeholder"
                                    helper={
                                      errors?.stakeholders
                                        ? getIn(
                                            errors,
                                            `stakeholders.[${index}].name`,
                                          )
                                        : ''
                                    }
                                    onChange={handleChange}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onBlur={handleBlur}
                                  />
                                </S.InputColStakeholder>
                                <S.InputColStakeholder className="mb">
                                  <FormikCpfInput
                                    name={`stakeholders.[${index}].cpf`}
                                    label="CPF*"
                                    value={stakeholder.cpf?.replace(
                                      /[^a-zA-Z0-9]/g,
                                      '',
                                    )}
                                    placeholder="XXX.XXX.XXX-XX"
                                    helper={
                                      errors?.stakeholders
                                        ? getIn(
                                            errors,
                                            `stakeholders.[${index}].cpf`,
                                          )
                                        : ''
                                    }
                                    onBlur={handleBlur}
                                    validateOnChange={false}
                                    allowLeadingZeros
                                    isNumericString={false}
                                    validateOnBlur={false}
                                    format="###.###.###-##"
                                    fullWidth
                                    onValueChange={(
                                      val: NumberFormatValues,
                                    ) => {
                                      setFieldValue(
                                        `stakeholders.[${index}].cpf`,
                                        val.value,
                                      );
                                    }}
                                  />
                                </S.InputColStakeholder>
                                <S.InputColStakeholder className="mb">
                                  <NumberField
                                    format="###########"
                                    name={`stakeholders.[${index}].rg`}
                                    label="RG*"
                                    value={
                                      stakeholder.rg?.replace(
                                        /[^a-zA-Z0-9]/g,
                                        '',
                                      ) || undefined
                                    }
                                    placeholder="XX.XXX.XXX-XX"
                                    helper={
                                      errors?.stakeholders
                                        ? getIn(
                                            errors,
                                            `stakeholders.[${index}].rg`,
                                          )
                                        : ''
                                    }
                                    validateOnChange={false}
                                    onBlur={handleBlur}
                                    allowLeadingZeros
                                    isNumericString={false}
                                    onValueChange={(
                                      val: NumberFormatValues,
                                    ) => {
                                      setFieldValue(
                                        `stakeholders.[${index}].rg`,
                                        val.value,
                                      );
                                    }}
                                  />
                                </S.InputColStakeholder>
                              </S.InputRow>
                              <S.InputRow>
                                <S.InputColStakeholder className="fe mb">
                                  <TextField
                                    name={`stakeholders.[${index}].email`}
                                    label="Email*"
                                    value={stakeholder.email}
                                    placeholder="email@v4company.com"
                                    helper={
                                      errors?.stakeholders
                                        ? getIn(
                                            errors,
                                            `stakeholders.[${index}].email`,
                                          )
                                        : ''
                                    }
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onBlur={handleBlur}
                                  />
                                </S.InputColStakeholder>
                                <S.InputColStakeholder className="se mb">
                                  <FormikPhoneInput
                                    name={`stakeholders.[${index}].phone`}
                                    label="Telefone*"
                                    value={stakeholder.phone?.replace(
                                      /[^a-zA-Z0-9]/g,
                                      '',
                                    )}
                                    placeholder="(99) 99999-9999"
                                    helper={
                                      errors?.stakeholders
                                        ? getIn(
                                            errors,
                                            `stakeholders.[${index}].phone`,
                                          )
                                        : ''
                                    }
                                    width="50%"
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onBlur={handleBlur}
                                    onValueChange={(
                                      val: NumberFormatValues,
                                    ) => {
                                      setFieldValue(
                                        `stakeholders.[${index}].phone`,
                                        val.value,
                                      );
                                    }}
                                  />
                                </S.InputColStakeholder>
                              </S.InputRow>
                            </>
                          );
                        })}
                      </div>
                    );
                  }}
                </FieldArray>
                <button
                  ref={sendFormRef}
                  type="submit"
                  style={{ display: 'none' }}
                >
                  Enviar
                </button>
              </>
            )}
          </S.Container>
        )}
      </Formik>
    </Modal>
  );
}
