import styled from 'styled-components';

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 2rem;
`;

export const CardTitle = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  margin-left: 0.313rem;
`;

export const CardLabel = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.813rem;
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.813rem;
  .sm {
    max-width: 25%;
  }
  .sm-span {
    font-size: 0.875rem !important;
  }
  .sm-label {
    font-size: 0.875rem;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardInfo = styled.div`
  width: 33%;
  height: 20.625rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
  background-color: var(--white);
  box-shadow: 0rem 0.625rem 1.25rem rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DotMenu = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 4rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-left: 0.625rem;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 2rem;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > h1 {
      margin-top: 0.75rem;
    }
  }
`;

export const unitLockedMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-dark-3);
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Nunito Sans';
  margin-bottom: 1.2rem;
`;
